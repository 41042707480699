@font-face {
    font-family: 'Dosis';
    src: local('Dosis'), url('../../fonts/Dosis-Regular.ttf') format('truetype'); 
  }

.gpt3__whatgpt3 {
    display: flex;
    flex-direction: column;
    
    padding: 5rem 2rem;

    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.gpt3__whatgpt3-feature {
    display: flex;
    align-items: center; 
    max-width: 100%;
    flex-wrap: wrap; 
}

/* Customizing feature component as per needs */
.gpt3__whatgpt3-feature .gpt3__features-container__feature {
    margin: 0;
}

/* Customizing feature component as per needs */
.gpt3__whatgpt3-feature .gpt3__features-container_feature-text {
    max-width: 700px;
}

.gpt3__whatgpt3-heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 20rem 0 2rem;
}

.gpt3__whatgpt3-heading h1 {
    font-size: 34px;
    line-height: 45px;
    font-weight: bold;
    font-family: 'Dosis', sans-serif;
    text-transform: uppercase;
    max-width: 510px;
    letter-spacing: 4px;
}

.gpt3__whatgpt3-heading p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: var(--color-subtext);
    cursor: pointer;
}

.gpt3__whatgpt3-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: center;
    margin-top: 2rem;
}

/* Customizing feature component as per needs */
.gpt3__whatgpt3-container .gpt3__features-container__feature {
    flex: 1;
    margin: 1rem;
    min-width: 300px;
    background-color: #F4CCCC;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    height: 700px;
    padding: 20px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    transition: all 0.3s ease;
    justify-content: unset;
}

/* Customizing feature component as per needs */
.gpt3__whatgpt3-container .gpt3__features-container_feature-text {
    margin-top: 0.5rem;
}

@media screen and (max-width: 850px) {
    .gpt3__whatgpt3-heading {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 0rem;
    }

    .gpt3__whatgpt3-heading p {
        margin-top: 1rem;
    }
}

@media (max-width: 768px) {
    .gpt3__whatgpt3-feature {
        flex-direction: column; 
    }
}

@media screen and (max-width: 702px) {
    .gpt3__whatgpt3-container .gpt3__features-container__feature {
        flex-direction: column;
    }

    .gpt3__whatgpt3-feature .gpt3__features-container__feature {
        flex-direction: column;
    }

    .gpt3__whatgpt3-feature .gpt3__features-container_feature-text {
        margin-top: 0.5rem;
    }

    .gpt3__whatgpt3-heading h1 {
        font-size: 2rem;
        line-height: 40px;
        margin: auto;
        margin-bottom: 60px;
    }
}

/* @media screen and (max-width: px) {
    .gpt3__whatgpt3-container .gpt3__features-container__feature {
        margin: 1rem 0;
    }
} */