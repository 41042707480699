.gpt3__blog {
    display: flex;
    flex-direction: column;    
    padding: 5rem 2rem;
}

.gpt3__features {
    display: flex;
    flex-direction: column;    
    padding: 5rem 2rem;
    margin: auto;
    max-width: 1200px;
}

.gpt3__blog-heading {
    width: 100%;
    text-align: left;
    margin-bottom: 5rem;
}

.gpt3__features-heading {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    text-align: left;
    margin-right: 5rem;
}

.gpt3__blog-heading h1 {
    font-size: 34px;
    line-height: 45px;
    font-weight: bold;
    font-family: 'Dosis', sans-serif;
    text-transform: uppercase;
    max-width: 510px;
    letter-spacing: 4px;
}

.gpt3__features-heading h1 {
    text-align: left;
    font-size: 34px;
    line-height: 45px;
    font-weight: bold;
    font-family: 'Dosis', sans-serif;
    text-transform: uppercase;
    max-width: 510px;
    letter-spacing: 4px;
}


.gpt3__blog-container {
    display: flex;
    flex-direction: column;
    margin: auto;
    max-width: 1200px;
    margin-bottom: 13rem;
}

.gpt3__features-container {
    display: flex;
    flex-direction: column;
    margin: auto;
    max-width: 1200px;
}


.heading-container {
    font-size: 34px;
    line-height: 45px;
    font-weight: bold;
    font-family: 'Dosis', sans-serif;
    text-transform: uppercase;
    letter-spacing: 4px;
    text-align: left;
    margin-bottom: 10rem;
}

.gpt3__blog-container_groupA {
    flex: 0.75;
    margin-right: 2rem;
}

.gpt3__blog-container_groupB {
    flex: 1;
    display: flex;
    margin: auto;
    justify-content: center;
}

.gpt3__features-container_groupA {
    flex: 0.75;
    margin-right: 2rem;
}

.gpt3__features-container_groupB {
    flex: 1;
    display: flex;
}


@media screen and (max-width: 990px) {
    .gpt3__blog-container_groupA {
        margin: 2rem 0;
    }

    .gpt3__blog-container_groupA .gpt3__blog-container_article  {
       width: 48%;
    }

    .gpt3__blog-container_groupA .gpt3__blog-container_article-image {
        height: 250px;
    }

    .gpt3__blog-container_groupB {
        display: flex;
        flex-wrap: wrap;
        width: 50%;
        margin: auto;
    }
}

@media screen and (max-width: 700px) {
    .gpt3__blog-container_groupB {
        grid-template-columns: repeat(1, 1fr);
    }

    .gpt3__blog-container_groupA .gpt3__blog-container_article  {
       width: 100%;
    }

    .gpt3__blog-heading h1 {
        font-size: 46px;
        line-height: 52px;
    }
}

@media screen and (max-width: 650px) {
    .gpt3__features-heading {
       margin: auto;
    }

    .gpt3__features-heading h1 {
        line-height: 40px;
        text-align: center;
    }
}