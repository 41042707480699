.gpt3__features-container__feature {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    height: 500px;
    margin: 1rem;
}

.gpt3__features-container__feature-img {
    width: 200px;
    height: 300px;
}

.gpt3__features-container__feature-title {
    flex: 1;
    margin-right: 2rem;
    color: black;
    margin-top: 15px;
}

.gpt3__features-container__feature-title h1 {
    font-size: 34px;
    line-height: 45px;
    font-weight: bold;
    font-family: 'Dosis', sans-serif;
    text-transform: uppercase;
    letter-spacing: 4px;
}

/* .gpt3__features-container__feature-title div {
    width: 38px;
    height: 3px;
    background: var(--gradient-bar);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 0.25rem;
} */

.gpt3__features-container_feature-text {
    flex: 2;
    display: flex;
}

.gpt3__features-container_feature-text p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 20px;
    line-height: 37px;
    color: black;
}

@media screen and (max-width: 550px) {
    .gpt3__features-container__feature-title h1 {
        font-size: 1.4rem;
        line-height: 22px;
    }

    .gpt3__features-container_feature-text p {
        font-size: 12px;
        line-height: 20px;
    }

    .gpt3__features-container__feature {
        margin: 1rem 0;
    }
}

@media screen and (max-width: 650px) {
    .gpt3__features-container__feature-img {
        width: 150px;
        height: 200px;
        margin: auto;
    }
}