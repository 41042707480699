.main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin-bottom: 4rem;
}

.animation-container  {
  flex: 1;
  padding: 20px;
  text-align: center;
}

.form-container  {
  flex: 1;
  padding: 20px;
  font-family: 'Dosis', sans-serif;
}


form {
  max-width: 700px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  margin-bottom: 4rem;
}

  label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
    text-align: left;
  }
  
  input,
  textarea {
    margin-top: 10px;
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  input[type="submit"] {
    background-color: #4caf50;
    color: white;
    cursor: pointer;
  }
  
  input[type="submit"]:hover {
    background-color: #45a049;
  }
  
  h1 {
    text-align: center;
    color: #333;
  }
  
  @media (max-width: 600px) {
    form {
      width: 90%;
    }
  }

  @media (max-width: 768px) {
    .main-container {
      flex-direction: column;
      text-align: center;
    }
  
    .animation-container,
    .form-container {
      order: 1; 
      flex: 1;
      width: 100%;
    }
  
    .form-container {
      max-width: none; 
    }
  }