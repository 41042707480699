.gpt3__navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Dosis', sans-serif;
    padding: 1rem 6rem;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000; 
    background-color: #F4CCCC;
    border-bottom: 1px solid #e3aeae; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.gpt3__navbar-links {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.gpt3__navbar-links_logo {
    margin-right: 2rem;
    display: flex; 
    align-items: center; 
}

.gpt3__navbar-links_logo img {
    max-width: 50px; 
    height: auto; 
  }

.logo-container {
    display: flex; 
    align-items: center; 
  }
  
  .logo-cinco {
    width: 50px; 
    height: auto; 
    margin-right: 10px; 
  }

.gpt3__navbar-links_container {
    display: flex;
    flex-direction: row;
    font-family: 'Dosis', sans-serif;
}

.logo-cinco {
    max-width: 100%; 
    width: auto; 
    height: auto;
    flex: 0 0 auto;
  }
  
.gpt3__navbar-sign {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.gpt3__navbar-links_container p,
.gpt3__navbar-sign p,
.gpt3__navbar-menu_container p {
    color: rgb(5, 39, 72);
    font-weight: 600;
    font-size: 13px;
    line-height: 25px;
    text-transform: uppercase;
    margin: 0 1rem;
    cursor: pointer;
    letter-spacing: 4px;
}

.gpt3__navbar-links_logo h1 {
    color: rgb(5, 39, 72);
    margin: 0 1rem;
    font-size: 1.5rem;
    line-height: 45px;
    font-weight: bold;
    font-family: 'Dosis', sans-serif;
    text-transform: uppercase;
    max-width: 510px;
    letter-spacing: 4px;
}

.gpt3__navbar-sign button,
.gpt3__navbar-menu_container button {
    padding: 0.5rem 1rem;
    color: #fff;
    background: #FF4820;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
}

.gpt3__navbar-menu {
    margin-left: 1rem;

    display: none;
    position: relative;
}

.gpt3__navbar-menu svg {
    cursor: pointer;
}

.gpt3__navbar-menu_container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;

    text-align: end;
    background: var(--color-footer);
    padding: 2rem;
    position: absolute;
    right: 0;
    top: 40px;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.gpt3__navbar-menu_container p {
    margin: 1rem 0;
}

.gpt3__navbar-menu_container-links-sign {
    display: none;
}

@media screen and (max-width: 1050px) {
    .gpt3__navbar-links_container {
        display: none;
    }

    .gpt3__navbar-menu {
        display: flex;
    }
}

@media screen and (max-width: 768px) {
    .logo-cinco {
      width: 40px; 
    }
  
    h1 {
      font-size: 18px; 
    }
  }

@media screen and (max-width: 700px) {
    .gpt3__navbar {
        padding: 1rem 4rem;
    }
}

@media screen and (max-width: 550px) {
    .gpt3__navbar {
        padding: 1rem;
    }

    .gpt3__navbar-sign {
        display: none;
    }

    .gpt3__navbar-menu_container {
        top: 20px;
    }

    .gpt3__navbar-menu_container-links-sign {
        display: block;
    }
}