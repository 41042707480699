.gpt3__feedback {
    display: flex;
    flex-direction: column;    
}

.gpt3__feedback-heading {
    width: 100%;
    text-align: left;
    margin-bottom: 5rem;
}

.gpt3__feedback-heading h1 {
    font-size: 34px;
    line-height: 45px;
    font-weight: bold;
    font-family: 'Dosis', sans-serif;
    text-transform: uppercase;
    max-width: 510px;
    letter-spacing: 4px;
}

.gpt3__feedback-container {
    display: flex;
    flex-direction: column;
    margin: auto;
    max-width: 1200px;
    margin-bottom: 20rem;
}

.gpt3__feedback-container-text {
    font-family: 'Dosis', sans-serif;
    font-size: 18px;
    font-weight: 800;
    line-height: 30.30px;
    letter-spacing: 0.05em;
    color: black;
    margin-bottom: 2rem;
    cursor: pointer;
    min-height: 60px;
}

.heading-container {
    font-size: 34px;
    line-height: 45px;
    font-weight: bold;
    font-family: 'Dosis', sans-serif;
    text-transform: uppercase;
    letter-spacing: 4px;
    text-align: left;
    margin-bottom: 10rem;
}

.carousel {
    position: relative;
    display: flex;
    align-items: center;
    overflow: hidden;
  }
  
  .arrow {
    position: absolute;
    top: 73%;
    transform: translateY(-50%);
    background: #F4CCCC; 
    color: white;
    border: 1px solid #ccc;
    cursor: pointer;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    border-radius: 50%;
    z-index: 1;
  }
  
  .prev {
    left: 0;
  }
  
  .next {
    right: 0;
  }
  
  .slide-container {
    display: flex;
    transition: transform 0.5s ease;
    scroll-snap-type: x mandatory;
    overflow-x: auto;
    width: 100%
  }
  
  .slide {
    flex: 0 0 auto; 
    scroll-snap-align: start;
    text-align: left;
    border: 1px solid #ccc;
    padding: 20px;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 8px;
    margin: 0 10px;

    background-color: #f4f4f4;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
  }
  

  .slide:first-child {
    margin-left: 0; 
  }
  
  .slide:last-child {
    margin-right: 0; 
  }
  
  .slide p {
    margin: 0;
    line-height: 1.4;
  }
  
  .slide p:first-child {
    font-size: 18px;
    font-weight: bold;
  }
  
  .slide p:last-child {
    font-style: italic;
    color: #888;
  }


  @media screen and (max-width: 768px) {
    .slide {
        flex: 0 0 calc(100%); 
        margin: 0 10px;
        height: 300px; 
        width: 100%;
    }

    .gpt3__feedback-container {
      margin: unset;
    }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
    .slide {
        flex: 0 0 calc(45% - 20px); 
    }
}

@media screen and (min-width: 1024px) {
    .slide {
        flex: 0 0 calc(33.33% - 20px); 
    }
}


