.gpt3__price {
    display: flex;
    flex-direction: column;   
}

.service-prices-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    font-family: 'Dosis', sans-serif;
    padding-bottom: 400px;
  }
  
  .service {
    background-color: #F4CCCC;
    border-radius: 10px;
    padding: 20px;
    margin: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 270px;
  }
  
  .service h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .service-details h3 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .service-details p {
    margin: 5px 0;
    font-size: 16px;
  }