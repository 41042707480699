.gpt3__features-container__feature {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;

    margin: 1rem;
}

.gpt3__features-container__feature:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(236, 103, 103, 0.15);
  }

.gpt3__features-container__feature-title {
    flex: 1;
    margin-right: 2rem;
}

.gpt3__features-container__feature-title-text {
    margin-left: 20px;
}

.gpt3__features-container__feature-title h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.04em;
    color: black;
}

.gpt3__features-container__feature-title-text p {
    font-family: var(--font-family);
    text-align: left;
    font-size: 1.3rem;
    line-height: 43px;
    letter-spacing: -0.04em;
    color: black;
    margin-top: 4%;
}

.gpt3__features-container__feature-title div {
    width: 38px;
    height: 3px;
    background: var(--gradient-bar);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 0.25rem;
}

.gpt3__features-container_feature-text p {
    font-family: 'Dosis', sans-serif;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: black;
    text-align: left;
}

@media screen and (max-width: 550px) {
    .gpt3__features-container__feature-title h1 {
        font-size: 1.2rem;
        line-height: 22px;
        margin-bottom: 50px;
    }

    .gpt3__features-container__feature-title-text p {
        font-size: 1.1rem;
        line-height: 27px;
    }

    .gpt3__features-container_feature-text p {
        font-size: 12px;
        line-height: 20px;
    }

    .gpt3__features-container__feature {
        margin: 1rem 0;
    }

    .gpt3__features-container__feature-title-text {
        margin-left: 0px;
    }
}