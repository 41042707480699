.gpt3__blog-container_article {
    height: 550px;
    display: flex;
    flex-direction: column;
    background-color: #F4CCCC;
    /* background-image: url('../../assets/heart.jpg'); */
    background-size: contain;
    background-repeat: no-repeat;
    padding: 30px 30px 110px 30px;
    margin: 18px 20px 18px 20px;
    border-radius: 24px 24px 24px 24px;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
}

.gpt3__blog-container_article-image {
    max-width: 100%;
    height: auto;
}

.gpt3__blog-container_article-image img {
    width: 100%;
    height: 120%;
}

.gpt3__blog-container_article-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem 1.5rem;
    height: 100%;
    margin-top: 60px;

}

.gpt3__blog-container_article-content p {
    font-family: 'Dosis', sans-serif;
    font-size: 14px;
    line-height: 35px;
    min-height: 70px;
    color: black;
    letter-spacing: 0.05em;
}

.gpt3__blog-container_article-content h3 {
    font-family: 'Dosis', sans-serif;
    font-size: 22px;
    font-weight: 800;
    line-height: 30.30px;
    letter-spacing: 0.05em;
    color: black;
    margin-bottom: 2rem;
    cursor: pointer;
    min-height: 60px;
}

.gpt3__blog-container_article-content p:last-child {
    cursor: pointer;
}


@media screen and (max-width: 550px) {
    .gpt3__blog-container_article-content h3 {
        font-size: 18px;
        line-height: 25p;
    }
}