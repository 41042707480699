.gpt3__survey {
  display: flex;
  flex-direction: column;    
  padding: 5rem 2rem;
  margin: auto;
  max-width: 1200px;
  }

  .gpt3__survey-heading {
    text-align: left;
    margin-bottom: 5rem;
}

  .gpt3__survey-container{
    display: flex;
    flex-direction: column;
    margin: auto;
    max-width: 1200px;
    background-color: #f4f4f4;
    border-radius: 24px 24px 24px 24px;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
  }

  .heading-container {
    font-size: 34px;
    line-height: 45px;
    font-weight: bold;
    font-family: 'Dosis', sans-serif;
    text-transform: uppercase;
    letter-spacing: 4px;
    text-align: left;
    margin-bottom: 10rem;
}
  
  .question {
    display: flex;
    flex-direction: column;
    width: 50%;
    padding-right: 10px;
    margin-right: 20px;
    font-family: 'Dosis', sans-serif;
    font-weight: 800;
    line-height: 30.30px;
    letter-spacing: 0.05em;
    color: black;
    margin-bottom: 2rem;
    min-height: 60px;
  }
  
  .bar {
    width: 40%;
    height: 30px;
    border-radius: 5px;
    overflow: hidden;
  }

  .survey-questions {
    display: flex;
    flex-direction: row;
    width: 100%;
    text-align: right;
  }
  
  .progress {
    height: 100%;
    background-color: #4caf50; 
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: bold;
    font-family: 'Dosis', sans-serif;
  }

  .legend {
    display: flex;
    flex-direction: row;
    width: 50%;
    margin-top: 35px;
    position: absolute;
    justify-content: space-around;
    right: 3%;
    transform: translateY(-50%);
  }

  .legend-container {
    position: relative;
  }
  
  .legend-item {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    font-family: 'Dosis', sans-serif;
  }
  
  .legend-color {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    border-radius: 19%;
  }
  
  .green {
    background-color: #4caf50;
  }
  
  .red {
    background-color: rgb(216, 138, 138);
  }
  
  .gray {
    background-color: gray;
  }

  .pie-container {
    width: 100%;
    max-width: 600px;
    display: flex;
    margin: auto;
  }

  @media screen and (max-width: 768px) {
    .heading-container {
      font-size: 20px;
      width: 100px;
    }

    .legend {
      width: 100%;
    }

    .recharts-surface {
      height: 100%; 
      width: 100%;
    }
}