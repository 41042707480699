/* Individual feature container */
.gpt3__features-container__featureV {
    background-color: #f4f4f4;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px 0;
    padding: 20px;
    display: flex;
    flex-direction: row; /* Change to row to align items side by side */
    align-items: center; /* Adjust alignment as per your design */
    justify-content: flex-start; /* Adjust as per your design */
    transition: all 0.3s ease;
  }
  
  /* Title and image container style */
  .gpt3__features-container__feature-titleV {
    margin-right: 20px; /* Adjust as needed for space between image and text */
  }
  
  .values-image {
    max-width: 100px; 
    height: auto; 
  }
  
  /* Title style */
  .gpt3__features-container__feature-titleV h1 {
    font-size: 34px;
    line-height: 45px;
    font-weight: bold;
    font-family: 'Dosis', sans-serif;
    text-transform: uppercase;
    max-width: 510px;
    letter-spacing: 4px;
  }
  
  /* Text content style */
  .gpt3__features-container__feature-textV p {
    font-family: var(--font-family);
    font-size: 1.2rem;
    line-height: 27px;
    color: black;
    text-align: left;
  }
  
  /* Hover effect */
  .gpt3__features-container__featureV:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }

  @media screen and (max-width: 990px) {
    .gpt3__features-container__featureV {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin-top: 20px;
        min-width: 300px;
    }

    .gpt3__features-container__feature-titleV {
      margin: auto;
    }
}

@media screen and (max-width: 700px) {
    .gpt3__blog-container_groupB {
        grid-template-columns: repeat(1, 1fr);
    }

    .gpt3__features-container__featureV  {
       width: 100%;
    }
}

@media screen and (max-width: 350px) {
.gpt3__features-container__featureV {
    min-width: unset;
}
}