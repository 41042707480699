.gpt3__header {
    display: flex;
    flex-direction: row;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
}

.img-thumbnail-1 {
    /* height: auto;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: -1;  */
    width: 100%;
    height: 100vh; 
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.fixed-image {
    position: fixed;
    /* top: -56px; */
    top: 0;
    left: 0;
    z-index: -1;
    object-fit: cover;
    width: 100%;
    height: calc(100% + 56px);
}

.img-content-1 {
    position: absolute;
    top: 50%; /* Align to the center */
    left: 50%; /* Align to the center */
    transform: translate(-50%, -50%); /* Center the image */
    max-width: 100%; /* Adjust maximum width */
    max-height: 100%; /* Adjust maximum height */
    width: auto; /* Adjust width */
    height: auto;
}

.gpt3__header-content {
    flex: 1;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.gpt3__header-content h1 {
    font-family: var(--font-family);
    text-shadow: 
    1px 1px 0 black,  
    1px 1px 1px black,  
    1px -1px 1px black;
    font-weight: 900;
    font-size: 3em;
    line-height: 75px;
    letter-spacing: 10px;
    font-family: 'Dosis', sans-serif;
    text-transform: uppercase;
    text-align: center;
}

.gpt3__header-content p {
    font-family: var(--font-family);
    font-weight: 900;
    font-size: 1.2rem;
    line-height: 28px;
    /*  color: #fff9d6; */
    color: white;
    text-shadow: 2px 2px 4px rgb(5 5 5 / 50%);
    margin-top: 1.5rem;
    letter-spacing: 2px;
    font-family: 'Dosis', sans-serif;
    max-width: 650px;
}

.text-container {
    background-color: rgba(255, 192, 203, 0.7);
    padding: 20px; 
    border-radius: 10px; 
    display: inline-block; 
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); 
}

.gpt3__header-content__input {
    width: 100%;
    margin: 2rem 0 1rem;

    display: flex;
    flex-direction: row;
}

.gpt3__header-content__input input {
    flex: 2;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: var(--color-footer);
    border: 2px solid var(--color-footer);
    padding: 0 1rem;
    outline: none;
    color: #fff;

    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.gpt3__header-content__input button {
    flex: 0.6;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: #FF4820;
    border: 2px solid #FF4820;
    padding: 0 1rem;
    color: #fff;
    cursor: pointer;
    outline: none;

    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.gpt3__header-content__people {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;

    margin-top: 2rem;
}

.gpt3__header-content__people img {
    width: 181.79px;
    height: 38px;
}

.gpt3__header-content__people p {
    margin: 0 0 0 1rem;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 12px;
    line-height: 38px;
    color: #fff;
    text-align: center;
}

.gpt3__header-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.gpt3__header-image img {
    width: 100%;
    height: 100%;
}

.gpt3__header {
    width: 100%;
    height: auto;
    position: relative;
    height: 100vh;
}

@media screen and (max-width: 1050px) {
    .gpt3__header {
        flex-direction: column;
    }

    .gpt3__header-content {
        margin: 0 0 3rem;
    }
}

@media screen and (max-width: 650px) {
    .gpt3__header-content h1 {
        font-size: 48px;
        line-height: 60px;
    }

    .gpt3__header-content__people {
        flex-direction: column;
    }

    .gpt3__header-content__people p {
        margin: 0;
    }

    .gpt3__header-content__input input,
    .gpt3__header-content__input button {
        font-size: 16px;
        line-height: 24px;
    }
}

@media screen and (max-width: 490px) {
    .gpt3__header-content h1 {
        font-size: 36px;
        line-height: 48px;
    }

    .gpt3__header-content__input input,
    .gpt3__header-content__input button {
        font-size: 12px;
        line-height: 16px;
    }
}

@media screen and (max-width: 319px) {
    .gpt3__header-content h1 {
        margin-top: 132px;
    }
}